import icons from "./icons.json";

interface IconProps {
  iconName: string;
  color?: string;
  height?: number;
  width?: number;
}

const Icon = ({ iconName, color, height = 20, width = 20 }: IconProps) => {
  const icon = icons.find((elem) => elem.name === iconName);
  const setFill = () => {
    return color ? { fill: color } : {};
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={setFill()}
      dangerouslySetInnerHTML={{ __html: icon ? icon?.value : "" }}
    ></svg>
  );
};

export default Icon;
