import { useModal } from "../../../../hooks/ModalContext";
import Button from "../../../../sharedComponents/Button/Button";
import styles from "../Details.module.scss";

interface Props {
  handleAccept: () => void;
}

export const AssignToMeModal = ({ handleAccept }: Props) => {
  const { closeModal } = useModal();

  const handleClick = () => {
    handleAccept();
    closeModal();
  };

  return (
    <div className={styles["modal-body"]}>
      <div className={styles["modal-text"]}>
        <p>Do you want to take charge of the category of this ticket?</p>
        <p>
          Once accepted, this ticket will be your responsibility and other
          operators will not be able to verify it.
        </p>
      </div>
      <div className={`${styles["button-container"]} flex`}>
        <Button
          label="Back"
          color="secondary"
          fontSize="modal"
          labelColor="dark"
          onClick={closeModal}
        />
        <Button label="Accept" fontSize="modal" onClick={handleClick} />
      </div>
    </div>
  );
};
