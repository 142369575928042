import { Box, Modal } from "@mui/material";
import { ReactNode } from "react";
import Icon from "../Icon/Icon";
import variables from "../../styles/variables.module.scss";
import styles from "./CustomModal.module.scss";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  modalTitle?: string;
  modalSubtitle?: string;
}

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: variables.white,
  width: "636px",
  minHeight: "300px",
  borderRadius: "8px",
  padding: "60px",
};

const CustomModal = ({
  isOpen,
  onClose,
  children,
  modalTitle,
  modalSubtitle,
}: ModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        {modalTitle && <h2 className={styles["modal-title"]}>{modalTitle}</h2>}
        {modalSubtitle && (
          <h3 className={styles["modal-subtitle"]}>{modalSubtitle}</h3>
        )}
        {children}
        <div
          className={`${styles["close-icon"]} pointer pos-absolute`}
          onClick={onClose}
        >
          <Icon iconName="close-icon" width={14} height={14} />
        </div>
      </Box>
    </Modal>
  );
};

export default CustomModal;
