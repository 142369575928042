import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Option } from "../Select/Select";
import { useState } from "react";
import { Checkbox, Chip, CircularProgress, SxProps } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import variables from "../../styles/variables.module.scss";
import styles from "./AutocompleteSelect.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface Props {
  placeholder?: string;
  options: Option[];
  onChange: (value: string | string[]) => void;
  multiSelect?: boolean;
  sxStyle?: SxProps;
  modalHeight?: boolean;
  maxTagsToShow?: number;
  defaultValues?: Option[];
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteSelect = ({
  placeholder = "Search",
  options,
  onChange,
  multiSelect = false,
  sxStyle,
  modalHeight = false,
  maxTagsToShow = 1,
  defaultValues = [],
}: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Option | Option[] | null>(
    multiSelect ? defaultValues : null
  );
  const loading = open && options.length === 0;

  const containerHeight =
    open && modalHeight ? `${options.length * 16 + 48}px` : "auto";

  const handleAutocompleteChange = (
    _: React.ChangeEvent<{}>,
    newValue: Option | Option[] | null
  ) => {
    setSelectedValue(newValue);

    if (multiSelect && Array.isArray(newValue)) {
      onChange(newValue.map((option) => option.value));
    } else if (!multiSelect && newValue) {
      onChange((newValue as Option).value);
    } else {
      onChange("");
    }
  };

  return (
    <div style={{ height: containerHeight }}>
      <Autocomplete
        multiple={multiSelect}
        value={selectedValue}
        className={styles["autocomplete-select"]}
        options={options}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableCloseOnSelect={multiSelect}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label}
        loading={loading}
        popupIcon={<ExpandMoreIcon />}
        sx={{
          ...sxStyle,
          "& .MuiSvgIcon-root": { color: variables.primaryLight },
          "& .MuiFormControl-root": {
            height: modalHeight ? "40px" : "36px",
          },
          "& .MuiInputLabel-outlined": {
            top: modalHeight ? "-6px" : "-8px",
          },
          ".MuiInputLabel-outlined.MuiInputLabel-shrink": {
            color: variables.primaryLight + " !important",
            top: modalHeight ? "0" : "1px",
          },
        }}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option, { inputValue, selected }) => {
          const matches = match(option.label, inputValue, {
            insideWords: true,
          });
          const parts = parse(option.label, matches);
          return (
            <li {...props}>
              <div>
                {multiSelect && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginBottom: "1px" }}
                    checked={selected}
                  />
                )}
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight
                        ? variables.primaryLight
                        : "inherit",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
        renderTags={(value, getTagProps) => {
          const tags = value
            .slice(0, maxTagsToShow)
            .map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option.label}
                key={option.value}
              />
            ));
          if (value.length > maxTagsToShow) {
            tags.push(
              <Chip
                key="additional-tags"
                label={`+${value.length - maxTagsToShow}`}
              />
            );
          }

          return tags;
        }}
      />
    </div>
  );
};

export default AutocompleteSelect;
