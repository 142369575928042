import Icon from "../../Icon/Icon";

interface SortingProps {
  isActive: boolean;
  direction: "asc" | "desc" | null;
}

const getIconName = (
  isActive: SortingProps["isActive"],
  direction: SortingProps["direction"]
): string | null => {
  if (!isActive) {
    return "sorting-inactive";
  }
  if (direction === "asc") {
    return "sorting-asc";
  }
  if (direction === "desc") {
    return "sorting-desc";
  }
  return null;
};

const SortingIcon = ({ isActive, direction }: SortingProps) => {
  const iconName = getIconName(isActive, direction);

  return (
    <div className="flex-inline" style={{ paddingLeft: "8px" }}>
      {iconName && <Icon iconName={iconName} width={10} height={10} />}
    </div>
  );
};

export default SortingIcon;
