import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import variables from "../../styles/variables.module.scss";
import BaloonCell from "../BaloonCell/BaloonCell";
import { useState } from "react";
import TablePaginator from "./TablePaginator/TablePaginator";
import Icon from "../Icon/Icon";
import SortingIcon from "./SortingIcon/SortingIcon";
import { Spinner } from "../Spinner/Spinner";

interface SortState<T> {
  column: keyof T | null;
  direction: "asc" | "desc" | null;
}

export interface IPagination {
  totalPages: number;
  currentPage: number;
  currentElements?: number;
  sort?: string;
  onPageChange: (event: number) => void;
  onSortChange: (event: any) => void;
}

export interface RequestOptions {
  page?: number;
  size?: number;
  sort?: string;
  direction?: "asc" | "desc";
  id?: number;
  title?: string;
  date?: string;
  predictedCategory?: string;
  operatorCategory?: string;
  status?: string | string[];
  operatorName?: string;
  startDate?: string;
  endDate?: string;
}

export interface Column<T> {
  id: keyof T; // Make sure the id matches a key of type T
  label: string;
  renderBaloonCell?: (value: T[keyof T], row: T) => string; // Return the value of the ballon cell to render
  labelWeight?: string;
  sortableKey?: string; // The key to sort by
}

interface CustomTableProps<T> {
  columns: Column<T>[];
  data: T[];
  navigateToDetail?: (row: T) => void; // go to detail page
  pagination?: IPagination;
  onSortChange?: (sort: { column: keyof T; order: string }) => void;
  defaultSortColumn?: keyof T;
  defaultSortDirection?: "asc" | "desc";
  loading: boolean;
}

function CustomTable<T>(props: Readonly<CustomTableProps<T>>) {
  const {
    columns,
    data,
    navigateToDetail,
    pagination,
    onSortChange,
    defaultSortColumn,
    defaultSortDirection,
    loading,
  } = props;

  const [sortState, setSortState] = useState<SortState<T>>({
    column: defaultSortColumn ?? null,
    direction: defaultSortDirection ?? null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(loading);

  const handleSort = (column: keyof T, sortableKey: string | undefined) => {
    if (sortableKey) {
      const isAsc =
        sortState.column === column && sortState.direction === "asc";
      setSortState({ column, direction: isAsc ? "desc" : "asc" });
      onSortChange?.({ column, order: isAsc ? "desc" : "asc" });
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "8px 8px 0 0",
        boxShadow: "0",
        "& .MuiPaper-root": { border: "none" },
        position: "relative",
        height: "100%",
      }}
    >
      {isLoading && <Spinner />}
      <Table>
        <TableHead sx={{ backgroundColor: variables.black }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id.toString()}
                sx={{
                  color: variables.white,
                  fontWeight: "bold",
                  fontFamily: variables.fontSecondary,
                  cursor: column.sortableKey ? "pointer" : "default",
                }}
                onClick={() => handleSort(column.id, column.sortableKey)}
              >
                {column.label}
                {column.sortableKey && (
                  <SortingIcon
                    isActive={sortState.column === column.id}
                    direction={
                      sortState.column === column.id
                        ? sortState.direction
                        : null
                    }
                  />
                )}
              </TableCell>
            ))}
            {navigateToDetail && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell
                  key={column.id.toString()}
                  sx={{
                    fontFamily: variables.fontSecondary,
                    fontWeight: column.labelWeight ?? "600",
                  }}
                >
                  {column.renderBaloonCell ? (
                    <BaloonCell
                      value={column
                        .renderBaloonCell(row[column.id], row)
                        .toString()}
                    />
                  ) : (
                    (row[column.id] as React.ReactNode)
                  )}
                </TableCell>
              ))}
              {navigateToDetail && (
                <TableCell
                  className="pointer"
                  onClick={() => navigateToDetail(row)}
                >
                  <Icon iconName="table-arrow" height={23} width={23} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination && pagination.totalPages > 1 && (
        <TablePaginator
          totalPages={pagination.totalPages}
          onPageChange={(page) => pagination.onPageChange(page)}
          currentPage={pagination.currentPage}
        />
      )}
    </TableContainer>
  );
}

export default CustomTable;
