import { useEffect, useState } from "react";
import { useModal } from "../../../../hooks/ModalContext";
import AutocompleteSelect from "../../../../sharedComponents/AutocompleteSelect/AutocompleteSelect";
import Button from "../../../../sharedComponents/Button/Button";
import styles from "../Details.module.scss";
import { getCategories } from "../../../../services/services";
import { Option } from "../../../../sharedComponents/Select/Select";

interface Props {
  handleAccept: (value: string) => void;
}

export const ChooseCategoryModal = ({ handleAccept }: Props) => {
  const { closeModal } = useModal();
  const [categories, setCategories] = useState<Option[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleClick = () => {
    handleAccept(selectedValue);
    closeModal();
  };

  const chooseCategories = async () => {
    try {
      const apiResponse = await getCategories();
      if (apiResponse) {
        setCategories(
          apiResponse.map((item) => ({
            label: item.name,
            value: item.id.toString(),
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    chooseCategories();
  }, []);

  return (
    <div className={styles["modal-body"]}>
      <div className={styles["modal-text"]}>
        <AutocompleteSelect
          options={categories}
          onChange={(value: string | string[]) =>
            setSelectedValue(value.toString())
          }
          modalHeight={true}
        />
      </div>
      <div className={`${styles["button-container"]} flex`}>
        <Button
          label="Back"
          color="secondary"
          fontSize="modal"
          labelColor="dark"
          onClick={closeModal}
        />
        <Button
          label="Accept"
          fontSize="modal"
          disabled={!selectedValue}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};
