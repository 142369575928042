import React, { useState, useEffect } from "react";
import styles from "./Sidebar.module.scss";
import Badge from "../Badge/Badge";
import variables from "../../styles/variables.module.scss";
import { Link, useLocation } from "react-router-dom";
import arrow_left from "../../assets/icons/arrow_left.svg";
import arrow_right from "../../assets/icons/arrow_right.svg";
import logo from "../../assets/logo.svg.png";
import block from "../../assets/icons/block.svg";
import book from "../../assets/icons/book.svg";
import health from "../../assets/icons/health.svg";
import block_selected from "../../assets/icons/block_selected.svg";
import book_selected from "../../assets/icons/book_selected.svg";
import health_selected from "../../assets/icons/health_selected.svg";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const showSidebar = () => setSidebarOpen(!sidebarOpen);

  const navigationMenu = [
    {
      text: "Dashboard",
      href: "/",
      icon: block,
      iconActive: block_selected,
      viewBox: "4 4 20 20",
      index: 0,
    },
    {
      text: "Performance",
      href: "/performance",
      icon: health,
      iconActive: health_selected,
      viewBox: "4 4 20 20",
      index: 1,
    },

    {
      text: "Train",
      href: "/train",
      icon: book,
      iconActive: book_selected,
      viewBox: "4 4 20 20",
      index: 2,
    },
  ];

  const navigationTitle = "Helix";

  return (
    <div className={`${styles.sidebar} ${sidebarOpen ? styles.active : ""}`}>
      {navigationTitle && (
        <div className={`${styles["navigation-title"]} flex flex-vcenter`}>
          <img src={logo} alt="" />
          {!sidebarOpen && <p> {navigationTitle} </p>}
        </div>
      )}
      <ul>
        {navigationMenu.map((item) => {
          const isActive =
            item.href === "/"
              ? location.pathname === item.href ||
                location.pathname.startsWith("/tickets/")
              : location.pathname.startsWith(item.href);
          return (
            <li
              key={item.index}
              className={`${styles.item} ${
                isActive ? styles.active : ""
              } flex flex-left flex-vcenter`}
            >
              <Badge color={variables.secondary} active={isActive} />
              <Link
                to={item.href}
                className={`${styles.box} ${
                  isActive ? styles.active : ""
                } flex flex-vcenter`}
              >
                <div className={`${styles.icon}`}>
                  <img src={isActive ? item.iconActive : item.icon} alt="" />
                </div>
                {!sidebarOpen ? item.text : ""}
              </Link>
            </li>
          );
        })}
      </ul>

      <div
        className={`${styles["navigation-footer"]} flex flex-vcenter pointer ${
          sidebarOpen ? "" : styles.active
        }`}
        onClick={showSidebar}
      >
        <img src={sidebarOpen ? arrow_right : arrow_left} alt="" />
      </div>
    </div>
  );
};
export default Sidebar;
