import styles from "./BaloonCell.module.scss";

interface BaloonCellProps {
  value: string;
  subValue?: string; // second part of the value if needed
}

const formatString = (string: string): string => {
  return string.toLowerCase().replace(/\s/g, "_");
};

export const BaloonCell = ({ value, subValue }: BaloonCellProps) => {
  return (
    <>
      {value ? (
        <div
          className={`${styles["baloon-cell"]} ${
            styles[formatString(value)]
          } flex flex-center gap-8`}
        >
          <div className={styles.dot} />
          <label>
            {value}
            {subValue}
          </label>
        </div>
      ) : (
        <div className="empty">—</div>
      )}
    </>
  );
};

export default BaloonCell;
