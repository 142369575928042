import ReactECharts, { EChartsOption } from "echarts-for-react";

interface Props {
  option: EChartsOption;
  height?: string;
}

const LineChart = ({ option, height = "300px" }: Props) => {
  return (
    <ReactECharts
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{ height: height }}
    />
  );
};

export default LineChart;
