export function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600); // calc hours
  const minutes = Math.floor((seconds % 3600) / 60); // calc minutes
  const remainingSeconds = seconds % 60; // calc seconds

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s`;
}
