import Icon from "../Icon/Icon";
import styles from "./AttachmentIcon.module.scss";

export interface AttachmentIconProps {
  name: string;
  extension: string;
  date: string;
}

const AttachmentIcon = ({ name, extension, date }: AttachmentIconProps) => {
  return (
    <div className={`${styles["attachment-container"]} flex pointer`}>
      <div className="pos-relative">
        <Icon iconName="attachment-icon" width={40} height={40} />
        <div
          className={`${styles["extension-overlay"]} ${styles[extension]} pos-absolute flex flex-center`}
        >
          {extension}
        </div>
      </div>
      <div className="flex flex-columns gap-8 flex-hcenter">
        <div className={styles["file-name"]}>{name}</div>
        <div className={styles["file-date"]}>{date}</div>
      </div>
    </div>
  );
};

export default AttachmentIcon;
