import { useEffect, useState } from "react";
import MainLayout from "../../../sharedComponents/MainLayout/MainLayout";
import Button from "../../../sharedComponents/Button/Button";
import { useParams } from "react-router-dom";
import AttachmentIcon, {
  AttachmentIconProps,
} from "../../../sharedComponents/AttachmentIcon/AttachmentIcon";
import styles from "./Details.module.scss";
import BaloonCell from "../../../sharedComponents/BaloonCell/BaloonCell";
import Icon from "../../../sharedComponents/Icon/Icon";
import { useModal } from "../../../hooks/ModalContext";
import { InfoModal, InfoProps } from "./Modals/InfoModal";
import { AssignToMeModal } from "./Modals/AssignToMeModal";
import { ChooseCategoryModal } from "./Modals/ChooseCategoryModal";
import { CloseTicketMeModal } from "./Modals/CloseTicketModal";
import Topbar from "../../../sharedComponents/Topbar/Topbar";
import {
  Explainations,
  ITicketDetails,
  assignTicket,
  closeTicket,
  confirmCategory,
  getExplainations,
  getTicketDetails,
} from "../../../services/services";
import { getFileExtension } from "../../../utils/getFileExtension";

const TicketDetails = () => {
  const { id } = useParams();
  const operatorId = "3c584c7ecf6c4cea8dcbc6a92a6bffca";
  const { openModal } = useModal();
  const [ticketDetails, setTicketDetails] = useState<ITicketDetails>();
  const [attachments, setAttachments] = useState<AttachmentIconProps[]>([]);
  const [explainations, setExplainations] = useState<InfoProps>();

  const showAssignToMeModal = () => {
    openModal(
      <AssignToMeModal handleAccept={() => handleAssignTicket()} />,
      "Assign to me"
    );
  };

  const showInfoModal = () => {
    if (explainations) {
      openModal(<InfoModal {...explainations} />, "Explainations");
    }
  };

  const showChooseCategoryModal = () => {
    openModal(
      <ChooseCategoryModal
        handleAccept={(value: string) => handleConfirmCategory(value)}
      />,
      "Choose category",
      "Choose the category to apply to the ticket."
    );
  };

  const showCloseTicketMeModal = (id: string) => {
    openModal(
      <CloseTicketMeModal handleAccept={() => handleCloseTicket(id)} />,
      "Close ticket"
    );
  };

  const getTicket = async (id: string) => {
    try {
      const apiResponse = await getTicketDetails(id);
      setTicketDetails(apiResponse);
      if (apiResponse.attachments) {
        setAttachments(
          apiResponse.attachments.map((attachment) => ({
            name: attachment.name,
            extension: getFileExtension(attachment.name ?? ""),
            date: apiResponse.date,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formatData = (data: Explainations[]): InfoProps => {
    const formatted: InfoProps = {
      TitleRelevance: "",
      ContentRelevance: "",
      MetadataRelevance: "",
      Attachments: [],
    };

    data.forEach((item, index) => {
      const { relevance, type } = item;
      const formattedRelevance = `${(relevance * 100).toFixed(2)}%`;

      switch (type) {
        case "TitleRelevance":
          formatted.TitleRelevance = formattedRelevance;
          break;
        case "ContentRelevance":
          formatted.ContentRelevance = formattedRelevance;
          break;
        case "MetadataRelevance":
          formatted.MetadataRelevance = formattedRelevance;
          break;
        case "AttachmentRelevance":
          formatted.Attachments.push(formattedRelevance);
          break;
        default:
          break;
      }
    });

    return formatted;
  };

  const getInfo = async (id: string) => {
    try {
      const apiResponse = await getExplainations(id);
      if (apiResponse) {
        setExplainations(formatData(apiResponse));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseTicket = async (id: string) => {
    try {
      await closeTicket(id);
    } catch (err) {
      console.log(err);
    } finally {
      window.location.reload();
    }
  };

  const handleAssignTicket = async () => {
    try {
      await assignTicket(operatorId, id ?? "");
    } catch (err) {
      console.log(err);
    } finally {
      window.location.reload();
    }
  };

  const handleConfirmCategory = async (categoryId: string) => {
    try {
      await confirmCategory(categoryId, id ?? "");
    } catch (err) {
      console.log(err);
    } finally {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (id) {
      getTicket(id);
      getInfo(id);
    }
  }, [id]);

  // <Spinner />

  return (
    <MainLayout>
      <div className="container">
        <Topbar
          navLinks={[
            { label: "dashboard /", path: "/" },
            { label: `ID ${id}` },
          ]}
          label="Tom Hardy"
        />

        <div className={`${styles["detail-container"]} flex gap-16`}>
          <div className={styles["left-container"]}>
            <h1 className={styles.title}>{ticketDetails?.title}</h1>
            <div className={styles["creation-date"]}>
              Created: {ticketDetails?.date}
            </div>
            <div className={styles.subtitle}>Description</div>
            <p
              className={styles["email-body"]}
              dangerouslySetInnerHTML={{
                __html: ticketDetails?.description ?? "",
              }}
            />
            <div className={styles.subtitle}>
              Attachments ({ticketDetails?.attachments.length})
            </div>
            <div
              className={`${styles["attachments-container"]} grid grid-3-columns`}
            >
              {attachments.map((attachment: AttachmentIconProps) => (
                <div key={attachment.name}>
                  <AttachmentIcon
                    name={attachment.name}
                    extension={attachment.extension}
                    date={attachment.date}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className={`${styles["right-container"]} pos-relative flex flex-columns gap-8`}
          >
            <div className={styles["info-box"]}>
              <div
                className={`${styles["info-item"]} ${styles["padding-bottom"]}`}
              >
                <div className={styles.info}>Status</div>
                <div className={styles.baloon}>
                  <BaloonCell value={ticketDetails?.status ?? ""} />
                </div>
              </div>
              <div className={styles["info-item"]}>
                <div className={styles.info}>Assigned to</div>
                {ticketDetails?.operatorName ? (
                  <div className={styles.sender}>
                    {ticketDetails?.operatorName}
                  </div>
                ) : (
                  <div className={styles.baloon}>
                    <BaloonCell value={""} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles["info-box"]}>
              <div className={`${styles["info-item"]} ${styles["info-item"]}`}>
                <div className={styles.info}>Sender</div>
                <div className={styles.sender}>{ticketDetails?.sender}</div>
              </div>
            </div>
            <div className={`${styles["info-box"]} ${styles.category}`}>
              <div className={`${styles.subtitle} ${styles["padding-bottom"]}`}>
                Category
              </div>
              <div
                className={`${styles["info-item"]} ${styles["padding-bottom"]}`}
              >
                <div className={styles.info}>AI</div>
                <div className={`${styles.baloon} flex gap-8`}>
                  <BaloonCell
                    value={ticketDetails?.predictedCategory ?? ""}
                    subValue={
                      ticketDetails?.accuracy
                        ? ` - ${ticketDetails.accuracy}%`
                        : ""
                    }
                  />
                  {explainations?.TitleRelevance && (
                    <div className="pointer" onClick={showInfoModal}>
                      <Icon iconName="info-icon" width={24} height={23} />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles["info-item"]}>
                <div className={styles.info}>Operator</div>
                {ticketDetails?.operatorCategory ? (
                  <div className={styles.sender}>
                    {ticketDetails?.operatorCategory}
                  </div>
                ) : (
                  <div className={styles.baloon}>
                    <BaloonCell value={""} />
                  </div>
                )}
              </div>
            </div>
            {ticketDetails?.status.toLowerCase() !== "closed" && (
              <div className="pos-absolute width-100 bottom">
                {!ticketDetails?.operatorName ? (
                  <Button
                    label="Assign to me"
                    onClick={() => showAssignToMeModal()}
                  />
                ) : (
                  <div className="flex gap-8">
                    <Button
                      label="Choose category"
                      color="secondary"
                      onClick={showChooseCategoryModal}
                    />
                    {!ticketDetails?.confirmed ? (
                      <Button
                        label="Confirm category"
                        onClick={() =>
                          handleConfirmCategory(
                            ticketDetails?.predictedCategoryId
                          )
                        }
                      />
                    ) : (
                      <Button
                        label="Close ticket"
                        onClick={() => showCloseTicketMeModal(id ?? "")}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

// close ticket quando o ho confermato quella AI o ho scelto la mia
// conferma categoria conferma solo la categoria dell'IA

export default TicketDetails;
