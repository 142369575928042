import qs from "query-string";
import { RequestOptions } from "../sharedComponents/Table/Table";

export function queryParamsSetter(options?: RequestOptions) {
  const queryString = qs.stringify(options as Record<string, any>, {
    skipNull: true,
    skipEmptyString: true,
  });
  return queryString ? `?${queryString}` : "";
}
