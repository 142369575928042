import MainLayout from "../../sharedComponents/MainLayout/MainLayout";
import styles from "./Performance.module.scss";
import Topbar from "../../sharedComponents/Topbar/Topbar";
import Icon from "../../sharedComponents/Icon/Icon";
import { useEffect, useState } from "react";
import SearchBar from "../../sharedComponents/SearchBar/SearchBar";
import LineChart from "../../sharedComponents/LineChart/LineChart";
import variables from "../../styles/variables.module.scss";
import { StatsTotal, getStats, getStatsTotal } from "../../services/services";
import { formatTime } from "../../utils/formatTime";

const chartData = {
  color: [variables.secondary, variables.danger],
  title: {
    text: "Ticket progress",
    textStyle: {
      fontSize: 18,
      fontWeight: 700,
      fontFamily: "Mulish",
      color: variables.black,
    },
  },
  legend: {
    data: ["Correct", "Incorrect"],
    right: 30,
    itemGap: 33,
  },
  grid: {
    top: "75",
    left: "10",
    right: "10",
    bottom: "40",
    containLabel: true,
  },
  xAxis: {
    type: "category",
    axisLine: {
      show: false,
    },
    axisLabel: {
      margin: 20,
    },
    axisTick: {
      show: false,
    },
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    axisLabel: {
      margin: 20,
    },
  },
  series: [
    {
      name: "Correct",
      data: [],
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 10,
      lineStyle: {
        width: 3,
      },
    },
    {
      name: "Incorrect",
      data: [],
      type: "line",
      smooth: true,
      symbol: "circle",
      symbolSize: 10,
      lineStyle: {
        width: 3,
      },
    },
  ],
  tooltip: {
    trigger: "axis",
  },
};

// const data = [
//   { name: "Supporto tecnico", percCorrect: 20.0, percWrong: 80.0 },
//   { name: "Vendite", percCorrect: 21.0, percWrong: 79.0 },
//   { name: "Supporto tecnico", percCorrect: 40.0, percWrong: 60.0 },
//   { name: "Amministrazione", percCorrect: 20.0, percWrong: 80.0 },
//   { name: "Vendite", percCorrect: 20.0, percWrong: 80.0 },
//   { name: "Supporto tecnico", percCorrect: 20.0, percWrong: 80.0 },
//   { name: "Amministrazione", percCorrect: 10.0, percWrong: 90.0 },
//   { name: "Vendite", percCorrect: 20.0, percWrong: 80.0 },
// ];

const Performance = () => {
  const [currentChartOptions, setCurrentChartOptions] =
    useState<Object>(chartData);

  const [statsData, setStatsData] = useState<StatsTotal>();

  const getChartData = async () => {
    try {
      const apiResponse = await getStats();
      setCurrentChartOptions({
        ...chartData,
        xAxis: {
          ...chartData.xAxis,
          data: apiResponse.xaxis.data.map((dateStr) => {
            const [year, month, day] = dateStr.split("-");
            return `${day}-${month}`;
          }),
        },
        series: apiResponse.series.map((item) => ({
          ...item,
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 10,
          lineStyle: {
            width: 3,
          },
        })),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getStatsData = async () => {
    try {
      const apiResponse = await getStatsTotal();
      setStatsData(apiResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getChartData();
    getStatsData();
  }, []);

  return (
    <MainLayout>
      <div className="container">
        <Topbar title="Performance" subtitle="(last month)" label="Tom Hardy" />
        <div
          className={`${styles["performance-container"]} flex flex-columns gap-16`}
        >
          <div
            className={`${styles["top-container"]} flex flex-space-between gap-24`}
          >
            <div className={`${styles["box"]} flex flex-vcenter`}>
              <div className={`${styles["left-box"]} flex flex-center`}>
                <Icon iconName="document-text-icon" width={32} height={32} />
              </div>
              <div
                className={`${styles["right-box"]} flex flex-columns flex-hcenter`}
              >
                <div style={{ fontWeight: 600 }}>Total tickets</div>
                <div
                  className={`${styles["second-row"]} flex flex-space-between flex-bottom`}
                >
                  <div className="flex flex-bottom">
                    <div className={styles["big-number"]}>
                      {statsData?.tickets}
                    </div>
                    <div className={styles["small-number"]}>
                      {" "}
                      / {statsData?.totalTickets}
                    </div>
                  </div>
                  <div className={`${styles["baloon-container"]} `}>
                    <div
                      className={`${styles["baloon-cell-red"]} flex flex-center `}
                    >
                      <div className={`${styles["baloon-text"]}`}>
                        {statsData?.perTotalTickets}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles["box"]} flex flex-rows flex-vcenter `}>
              <div className={`${styles["left-box"]} flex flex-center`}>
                <Icon iconName="task-square-icon" width={32} height={32} />
              </div>
              <div
                className={`${styles["right-box"]} flex flex-columns flex-hcenter`}
              >
                <div className={`flex flex-rows gap-4`}>
                  <div style={{ fontWeight: 600 }}>Status tickets out of</div>
                  <div style={{ fontWeight: 900 }}>{statsData?.tickets}</div>
                </div>
                <div className={`${styles["second-row"]} flex flex-bottom`}>
                  <div className={styles["green-number"]}>
                    {statsData?.correct}
                  </div>
                  <div
                    className={`${styles["baloon-cell-green"]} flex flex-center`}
                  >
                    <div className={`${styles["baloon-text"]}`}>
                      {statsData?.perCorrectTickets}%
                    </div>
                  </div>
                  <div className={styles["red-number"]}>
                    {statsData?.notCorrect}
                  </div>
                  <div
                    className={`${styles["baloon-cell-red"]} flex flex-center`}
                  >
                    <div className={`${styles["baloon-text"]}`}>
                      {statsData?.perNotCorrectTickets}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles["box"]} flex flex-rows flex-vcenter `}>
              <div className={`${styles["left-box"]} flex flex-center`}>
                <Icon iconName="timer-icon" width={32} height={32} />
              </div>
              <div
                className={`${styles["right-box"]} flex flex-columns flex-hcenter`}
              >
                <div style={{ fontWeight: 600 }}>Average processing time</div>
                <div
                  className={`${styles["second-row"]} flex flex-space-between flex-bottom`}
                >
                  <div className={styles["big-number"]}>
                    {statsData?.average ? formatTime(statsData?.average) : ""}
                  </div>
                  <div className={`${styles["baloon-container"]} `}>
                    <div
                      className={`${styles["baloon-cell-green"]} flex flex-center`}
                    >
                      <div className={`${styles["baloon-text"]}`}>
                        {statsData?.percAverage}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={`${styles["body-container"]} flex gap-24`}> */}
          {/* <div className={`${styles["left-container"]}`}> */}
          <div className={`${styles["chart"]}`}>
            <LineChart
              option={currentChartOptions}
              height="calc(100vh - 220px)"
            />
          </div>
          {/* </div> */}
          {/* <div
              className={`${styles["right-container"]} pos-relative flex flex-columns gap-8`}
            >
              <div className={styles["titlebox"]}>Top 10 categories</div>
              <SearchBar data={data} />
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </MainLayout>
  );
};

export default Performance;
