import styles from "../Details.module.scss";

export interface InfoProps {
  TitleRelevance: string;
  ContentRelevance: string;
  MetadataRelevance: string;
  Attachments: string[];
}

export const InfoModal = (data: InfoProps) => {
  return (
    <div className={styles["modal-body"]}>
      <ul className="flex flex-columns">
        <li className="flex">
          <div className={styles["modal-question"]}>Subject:</div>
          <span className={styles["modal-answer"]}>{data?.TitleRelevance}</span>
        </li>
        <li className="flex">
          <div className={styles["modal-question"]}>Body:</div>
          <span className={styles["modal-answer"]}>
            {data?.ContentRelevance}
          </span>
        </li>
        <li className="flex">
          <div className={styles["modal-question"]}>Metadati:</div>
          <span className={styles["modal-answer"]}>
            {data?.MetadataRelevance}
          </span>
        </li>
        <li>
          <ul className={`${styles.tree} flex flex-columns`}>
            {data?.Attachments.length > 0 && (
              <li>
                <div className={styles["modal-question"]}>Allegati:</div>
              </li>
            )}
            {data?.Attachments.map((attachment, index) => (
              <li className={`${styles["tree-element"]} flex`} key={index}>
                <div className={styles["modal-question"]}>
                  Allegato {index + 1}:
                </div>
                <span className={styles["modal-answer"]}>{attachment}</span>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
};
