import styles from "./Dropdown.module.scss";
import dropdown_arrow from "../../assets/icons/dropdown_arrow.svg";
import man from "../../assets/man.png";

interface DropdownProps {
  label: string;
  onClick?: () => {};
}

const Dropdown = ({ label, onClick }: DropdownProps) => {
  return (
    <div className={`${styles.dropdown} flex flex-vcenter pointer`}>
      <div className={styles.avatar}>
        <img src={man} alt="" />
      </div>
      <span className={styles.label}>{label}</span>
      <div className={`${styles.arrow} flex flex-center`}>
        <img src={dropdown_arrow} onClick={() => {}} alt="" />
      </div>
    </div>
  );
};

export default Dropdown;
