import MainLayout from "../../sharedComponents/MainLayout/MainLayout";
import maintenanceImage from "../../assets/maintenace-image.svg";
import styles from "./Train.module.scss";

const Train = () => {
  return (
    <MainLayout>
      <div
        className={`${styles["train-container"]} flex flex-columns gap-16 container flex-center`}
        style={{ padding: "23px" }}
      >
        <img src={maintenanceImage} alt="maintenance" />
        <h1>We are Under Maintenace.</h1>
        <h3>Will be Back Soon!</h3>
      </div>
    </MainLayout>
  );
};

export default Train;
