import { InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../Icon/Icon";
import styles from "./DateRangePicker.module.scss";
import it from "date-fns/locale/it";

type DateRangeInputProps = {
  placeholder: string;
  resetInput?: boolean;
  type: "date" | "multiDate";
  onMultiChange?: (startDate: Date | null, endDate: Date | null) => void;
  onChange?: (date: Date | null) => void;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  minDate?: Date;
  disabled?: boolean;
};

const calendarAdornment = (
  <InputAdornment position="end">
    <IconButton>
      <Icon iconName="calendar" height={24} width={24} />
    </IconButton>
  </InputAdornment>
);

const CustomInput = forwardRef((props: any, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    placeholder={props.placeholder}
    label={props.placeholder}
    variant="outlined"
    InputProps={{
      readOnly: true,
      endAdornment: calendarAdornment,
    }}
    className={styles["date-input"]}
  />
));

const DateRangePicker: React.FC<DateRangeInputProps> = ({
  onMultiChange,
  onChange,
  type = "multiDate",
  placeholder,
  resetInput = false,
  initialStartDate,
  initialEndDate,
  minDate,
  disabled = false,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(
    initialStartDate ?? null
  );
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ?? null);
  const [date, setDate] = useState<Date | null>(initialStartDate ?? null);
  const inputRef = useRef(null);
  registerLocale("it", it);

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onMultiChange?.(start, end);
  };

  const handleDateChange = (date: Date | null) => {
    setDate(date);
    onChange?.(date);
  };

  useEffect(() => {
    if (resetInput) {
      setStartDate(initialStartDate ?? null);
      setEndDate(initialEndDate ?? null);
      setDate(null);
    }
  }, [resetInput]);

  return (
    <>
      {type === "multiDate" ? (
        <DatePicker
          locale="it"
          selected={startDate}
          onChange={handleDateRangeChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          dateFormat="dd/MM/yyyy"
          placeholderText={placeholder}
          customInput={<CustomInput inputRef={inputRef} />}
          calendarClassName={styles["calendar"]}
          popperClassName={styles["calendar-popper"]}
          enableTabLoop={false}
          minDate={minDate ? new Date(minDate) : undefined}
          disabled={disabled}
        />
      ) : (
        <DatePicker
          locale="it"
          selected={date}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          placeholderText={placeholder}
          customInput={<CustomInput inputRef={inputRef} />}
          calendarClassName={styles["calendar"]}
          popperClassName={styles["calendar-popper"]}
          enableTabLoop={false}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default DateRangePicker;
