import Icon from "../../Icon/Icon";
import styles from "./TablePaginator.module.scss";

interface Props {
  totalPages: number;
  currentPage: number;
  siblingCount?: number; // number of pages to display before/after the current page
  onPageChange: (event: any) => void;
}

const TablePaginator = ({
  totalPages,
  siblingCount = 1,
  currentPage,
  onPageChange,
}: Props) => {
  const pages = [];
  const dots = "...";

  let start = Math.max(currentPage - siblingCount, 0);
  let end = Math.min(currentPage + siblingCount, totalPages - 1);

  // add first and last page buttons
  if (start > 0) {
    pages.push(0);
    if (start > 1) {
      pages.push(dots);
    }
  }
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  if (end < totalPages - 1) {
    if (end < totalPages - 2) {
      pages.push(dots);
    }
    pages.push(totalPages - 1);
  }

  return (
    <ul className="flex gap-8 no-select flex-right padding-bottom-standard">
      {/* Left navigation arrow */}
      <li
        className={`${styles["pagination-item"]} ${
          currentPage === 0 && styles["disabled"]
        } flex flex-center`}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <div className={`${styles.arrow} ${styles.left} flex flex-center`}>
          <Icon iconName={"pag-arrow"} height={14} width={14} />
        </div>
      </li>
      {pages.map((page) => {
        if (page === dots) {
          return (
            <li
              key={page}
              className={`${styles["pagination-item"]} ${styles.dots} flex flex-center `}
            >
              ...
            </li>
          );
        }
        return (
          <li
            key={page}
            className={`${styles["pagination-item"]} ${
              page === currentPage && styles["selected"]
            } flex flex-center`}
            onClick={() => onPageChange(page)}
          >
            {+page + 1}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        className={`${styles["pagination-item"]} ${
          currentPage === totalPages - 1 && styles["disabled"]
        }`}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <div className={`${styles.arrow} ${styles.right} flex flex-center`}>
          <Icon iconName={"pag-arrow"} height={14} width={14} />
        </div>
      </li>
    </ul>
  );
};

export default TablePaginator;
