import axios, { AxiosResponse } from "axios";
import { Pagination, Ticket } from "../pages/Home/Home";
import { RequestOptions } from "../sharedComponents/Table/Table";
import { queryParamsSetter } from "../utils/queryParamsSetter";

const baseRoot = "https://api.classifier.frontiere.io/api/webapp-service/";

export interface TicketList {
  data: Ticket[];
  pagination: Pagination;
}

interface Attachment {
  id: number;
  name: string;
  type: string;
}

export interface ITicketDetails {
  attachments: Attachment[];
  date: string;
  description: string;
  id: string;
  operatorCategory: string;
  operatorName: string;
  predictedCategory: string;
  predictedCategoryId: string;
  confirmed: boolean;
  sender: string;
  status: string;
  title: string;
  accuracy: number;
}

export interface Category {
  id: number;
  name: string;
}

export interface Explainations {
  relevance: number;
  type: string;
  // TitleRelevance: Subject
  // ContentRelevance: body
  // MetadataRelevance: metadati
  // AttachmentRelevance: attachments
}

interface ChartData {
  data: number[];
  name: string;
}
interface Legend {
  data: string[];
}
interface XAxis {
  data: string[];
  type: string;
}
export interface Stats {
  legend: Legend;
  series: ChartData[];
  xaxis: XAxis;
}

export interface StatsTotal {
  average: number; // 3 - tempo medio
  correct: number; // 2 verde
  notCorrect: number; // 2 red
  perCorrectTickets: number; // 2 verde perc
  perNotCorrectTickets: number; // 2 red perc
  perTotalTickets: number; // 1 - perc
  percAverage: number; // 3 - perc tempo medio
  tickets: number; // 1 - ticket elaborati
  totalTickets: number; // 1 - ticket totali
}

export const getTicketsList = async (
  params?: RequestOptions
): Promise<TicketList> => {
  try {
    const queryString = queryParamsSetter(params);
    const response = await axios.get<TicketList>(
      `${baseRoot}tickets${queryString}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getTicketDetails = async (
  ticketId: string
): Promise<ITicketDetails> => {
  try {
    const response = await axios.get<{ data: ITicketDetails }>(
      `${baseRoot}tickets/${ticketId}`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const closeTicket = async (ticketId: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(`${baseRoot}tickets/${ticketId}`);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCategories = async (): Promise<Category[]> => {
  try {
    const response = await axios.get<{ data: Category[] }>(
      `${baseRoot}tickets/categories`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStatus = async (): Promise<string[]> => {
  try {
    const response = await axios.get<{ data: string[] }>(
      `${baseRoot}tickets/status`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const confirmCategory = async (
  categoryId: string,
  ticketId: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(
      `${baseRoot}tickets/${ticketId}/categories/${categoryId}`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const assignTicket = async (
  operatorId: string,
  ticketId: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(
      `${baseRoot}tickets/${ticketId}/operator/${operatorId}`
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getExplainations = async (
  ticketId: string
): Promise<Explainations[]> => {
  try {
    const response = await axios.get(
      `${baseRoot}tickets/${ticketId}/explainations`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStats = async (): Promise<Stats> => {
  try {
    const response = await axios.get<{ data: Stats }>(
      `${baseRoot}tickets/stats`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStatsTotal = async (): Promise<StatsTotal> => {
  try {
    const response = await axios.get<{ data: StatsTotal }>(
      `${baseRoot}tickets/total`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
