import { useEffect, useState } from "react";
import CustomTable, {
  Column,
  IPagination,
  RequestOptions,
} from "../../sharedComponents/Table/Table";
import MainLayout from "../../sharedComponents/MainLayout/MainLayout";
import { useNavigate } from "react-router-dom";
import Topbar from "../../sharedComponents/Topbar/Topbar";
import styles from "./Home.module.scss";
import AutocompleteSelect from "../../sharedComponents/AutocompleteSelect/AutocompleteSelect";
import DateRangePicker from "../../sharedComponents/DateRangePicker/DateRangePicker";
import { getStatus, getTicketsList } from "../../services/services";
import { toLocalISOString } from "../../utils/formatISO";
import { Option } from "../../sharedComponents/Select/Select";
import CachedIcon from "@mui/icons-material/Cached";

export type Ticket = {
  id: number;
  title: string;
  date: string;
  predictedCategory: string;
  operatorCategory: string;
  status: string;
  operatorName: string;
};

export interface Pagination {
  currentElements: number;
  currentPage: number;
  size: number;
  totalPages: number;
  totalResults: number;
}

const tableColumns: Column<Ticket>[] = [
  {
    id: "id",
    label: "ID Ticket",
    labelWeight: "bold",
    sortableKey: "name",
  },
  { id: "title", label: "Title", sortableKey: "title" },
  { id: "date", label: "Date", sortableKey: "date" },
  {
    id: "predictedCategory",
    label: "AI Category",
    sortableKey: "predictedCategory",
    renderBaloonCell: (predictedCategory) =>
      predictedCategory?.toString() ?? "",
  },
  {
    id: "operatorCategory",
    label: "Operator Caregory",
    sortableKey: "operatorCategory",
    renderBaloonCell: (operatorCategory) => operatorCategory?.toString() ?? "",
  },
  {
    id: "status",
    label: "Status",
    sortableKey: "status",
    renderBaloonCell: (status) => status?.toString() ?? "",
  },
  { id: "operatorName", label: "Operator", sortableKey: "operatorName" },
];

const Home = () => {
  const navigate = useNavigate();
  const [statusSelectedValue, setStatusSelectedValue] = useState<
    string | string[]
  >("");
  const [dateRangeSelectedValue, setDateRangeSelectedValue] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>();
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);
  const [tableData, setTableData] = useState<Ticket[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [currentPage, setCurrentPage] = useState(0);
  const [tablePagination, setTablePagination] = useState<IPagination>({
    totalPages: 1,
    currentPage: currentPage,
    onPageChange: setCurrentPage,
    onSortChange: () => {},
  });
  const defaultParams: RequestOptions = {
    page: 0,
    size: 8,
    sort: "date,desc",
  };
  const savedParams = localStorage.getItem("tableParams");
  const initialParams = savedParams ? JSON.parse(savedParams) : defaultParams;
  const [params, setParams] = useState<RequestOptions>(initialParams);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateToDetail = (row: Ticket) => {
    navigate(`/tickets/${row.id}`);
  };

  const handleSortChange = (sort: any) => {
    setParams({
      ...params,
      sort: `${sort.column},${sort.order}`,
      size: 8,
    });
  };

  const getTableData = async (params?: RequestOptions) => {
    setIsLoading(true);
    try {
      const apiResponse = await getTicketsList(params);
      setTableData(apiResponse.data);
      setPagination(apiResponse.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusOptions = async () => {
    try {
      const apiResponse = await getStatus();
      if (apiResponse) {
        setStatusOptions(
          apiResponse.map((item) => ({
            label: item,
            value: item,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dateRangeSelectedValue?.startDate && dateRangeSelectedValue?.endDate) {
      const modifiedDateObject = {
        startDate: toLocalISOString(dateRangeSelectedValue.startDate),
        endDate: toLocalISOString(dateRangeSelectedValue.endDate),
      };
      setParams({
        ...params,
        startDate: modifiedDateObject.startDate,
        endDate: modifiedDateObject.endDate,
      });
    }

    if (statusSelectedValue) {
      setParams({
        ...params,
        status: statusSelectedValue,
      });
    }
  }, [statusSelectedValue, dateRangeSelectedValue]);

  useEffect(() => {
    if (pagination) {
      setTablePagination({
        ...tablePagination,
        totalPages: pagination.totalPages,
        currentPage: pagination.currentPage,
      });
    }
  }, [pagination]);

  useEffect(() => {
    setParams({ ...params, page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    // params persistence
    localStorage.setItem("tableParams", JSON.stringify(params));

    getTableData(params);
  }, [params]);

  useEffect(() => {
    getStatusOptions();
  }, []);

  return (
    <MainLayout>
      <div className="container">
        <Topbar title="Dashboard" label="Tom Hardy">
          <div className="flex gap-8 flex-vcenter">
            <div style={{ width: "300px" }}>
              <DateRangePicker
                type="multiDate"
                placeholder={"Date Range"}
                onMultiChange={(startDate, endDate) => {
                  setDateRangeSelectedValue({ startDate, endDate });
                }}
                initialStartDate={
                  params.startDate ? new Date(params.startDate) : null
                }
                initialEndDate={
                  params.endDate ? new Date(params.endDate) : null
                }
              />
            </div>
            <AutocompleteSelect
              placeholder="Status"
              sxStyle={{ width: "300px" }}
              multiSelect={true}
              options={statusOptions}
              onChange={(value: string | string[]) =>
                setStatusSelectedValue(value)
              }
              defaultValues={
                Array.isArray(params.status)
                  ? params.status.map((item) => ({
                      label: item,
                      value: item,
                    }))
                  : []
              }
            />
            <div
              className="pointer"
              onClick={() => {
                window.location.reload();
              }}
            >
              <CachedIcon sx={{ color: "#00adb4" }} />
            </div>
          </div>
        </Topbar>
        <div className={styles["table-container"]}>
          {tableData?.length === 0 ? (
            <div>Nessun dato</div>
          ) : (
            <CustomTable<Ticket>
              columns={tableColumns}
              data={tableData}
              navigateToDetail={navigateToDetail}
              onSortChange={handleSortChange}
              pagination={tablePagination}
              defaultSortColumn={
                (params.sort?.split(",")[0] as keyof Ticket | undefined) ??
                "date"
              }
              defaultSortDirection={
                (params.sort?.split(",")[1] as "asc" | "desc" | undefined) ??
                "desc"
              }
              loading={isLoading}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
