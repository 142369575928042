import Dropdown from "../Dropdown/Dropdown";
import styles from "./Topbar.module.scss";
import { Link } from "react-router-dom";

interface TopbarProps {
  title?: string;
  subtitle?: string;
  navLinks?: Array<{
    label: string;
    path?: string;
  }>;
  label: string;
  children?: React.ReactNode;
}

const Topbar: React.FC<TopbarProps> = ({
  title,
  subtitle,
  navLinks,
  label,
  children,
}) => {
  return (
    <div
      className={`${styles.topbar} ${
        navLinks ? styles.line : ""
      } flex flex-vcenter`}
    >
      {title && <div className={`${styles.title} `}>{title}</div>}
      {subtitle && <div className={`${styles.subtitle} `}>{subtitle}</div>}
      {navLinks && (
        <div className={`${styles.breadcrumb} flex flex-hcenter flex-columns`}>
          {navLinks?.map((link) => (
            <div key={link.label}>
              {link.path ? (
                <Link to={link.path} className={`flex flex-vcenter`}>
                  {link.label}
                </Link>
              ) : (
                <span>{link.label}</span>
              )}
            </div>
          ))}
        </div>
      )}
      <div className="flex gap-40 flex-right width-100">
        {children && (
          <div className={`${styles.content} flex flex-vcenter`}>
            {children}
          </div>
        )}
        <Dropdown label={label}></Dropdown>
      </div>
    </div>
  );
};

export default Topbar;
