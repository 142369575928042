import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import CustomModal from "../sharedComponents/CustomModal/CustomModal";

interface ModalContextType {
  openModal: (content: ReactNode, title?: string, subtitle?: string) => void;
  closeModal: () => void;
}

interface ModalProviderProps {
  children: ReactNode;
}

const ModalContext = createContext<ModalContextType>({} as ModalContextType);

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string | undefined>(undefined);
  const [modalSubtitle, setModalSubtitle] = useState<string | undefined>(
    undefined
  );

  const openModal = (content: ReactNode, title?: string, subtitle?: string) => {
    setModalContent(content);
    setModalTitle(title);
    setModalSubtitle(subtitle);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
    setModalTitle(undefined);
    setModalSubtitle(undefined);
  };

  const contextValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [modalContent, modalOpen, modalTitle, modalSubtitle]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
      <CustomModal
        isOpen={modalOpen}
        onClose={closeModal}
        modalTitle={modalTitle}
        modalSubtitle={modalSubtitle}
      >
        {modalContent}
      </CustomModal>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
