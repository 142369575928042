import styles from "./Badge.module.scss";

interface BadgeProps {
  color: string;
  active: boolean;
}

const Badge = ({ color, active }: BadgeProps) => {
  return (
    <div
      className={`${styles.badge} ${active ? styles.active : ""}`}
      style={{ backgroundColor: color }}
    ></div>
  );
};

export default Badge;
