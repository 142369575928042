import Sidebar from "../Sidebar/Sidebar";
import styles from "./MainLayout.module.scss";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className={`${styles["main-layout"]}`}>
      <Sidebar />
      <div className={`${styles["content"]}`}>{children}</div>
    </div>
  );
};

export default MainLayout;
