import { MouseEventHandler } from "react";
import styles from "./Button.module.scss";

interface ButtonProps {
  label: string;
  color?: "primary" | "secondary";
  labelColor?: "light" | "dark";
  size?: "small" | "medium" | "large";
  fontSize?: "standard" | "modal";
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button = ({
  label,
  color = "primary",
  labelColor = "light",
  size = "large",
  fontSize = "standard",
  disabled,
  onClick,
}: ButtonProps) => {
  return (
    <button
      className={`${styles.button} ${styles[color]} ${styles[labelColor]} ${
        styles[fontSize]
      } ${styles[size]} ${disabled && styles["disabled"]}`}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={styles.label}>{label}</span>
    </button>
  );
};

export default Button;
