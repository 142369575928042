import { useModal } from "../../../../hooks/ModalContext";
import styles from "../Details.module.scss";
import Button from "../../../../sharedComponents/Button/Button";

interface Props {
  handleAccept: () => void;
}

export const CloseTicketMeModal = ({ handleAccept }: Props) => {
  const { closeModal } = useModal();

  const handleClick = () => {
    handleAccept();
    closeModal();
  };

  return (
    <div className={styles["modal-body"]}>
      <div className={styles["modal-text"]}>
        Are you sure you want to close the ticket have you taken?
      </div>
      <div className={`${styles["button-container"]} flex`}>
        <Button
          label="Back"
          color="secondary"
          fontSize="modal"
          labelColor="dark"
          onClick={closeModal}
        />
        <Button label="Close ticket" fontSize="modal" onClick={handleClick} />
      </div>
    </div>
  );
};
